import { unique, without } from '../../utils';
import { getRulesOfTypeForOption, getRulesForOptions, getOneOfRulesForOption } from '../../components/Deal/helpers';

const initialState = {
  currentStep: 1,
  fetched: false,
  annualMileage: 5000,
  contractLength: 48,
  monthlyPrice: 0,
  maintainedMonthlyPrice: 0,
  initialPaymentPrice: 0,
  maintainedInitialPaymentPrice: 0,
  initialMonths: 9,
  maintenanceSelected: false,
  isFinanceLease: false,
  selected: {
    optionIds: [],
    // ok this is dumb but for pricing purposes we need to know which options you actually selected
    actuallySelectedOptionIds: [],
    ruleIds: [],
  },
  offerId: null,
  maintainedOfferId: null,
  options: [],
  rules: [],
  categories: {},
  initialised: false,
  URLParamsInit: false,
  URLParamsApplied: false,
  isTurbolinksLoad: true,
  salesView: true,
};

//    ACTIONS
//    addOption :: (String, State) -> State
const addOption = (id, state) => {
  let newActualOptionIds = unique(state.selected.actuallySelectedOptionIds.concat([id]));
  let newSelectedOptions = unique(state.selected.optionIds.concat([id]));
  const oneOfRules = getOneOfRulesForOption(id, state);

  if (oneOfRules.length > 0) {
    const otherOptionIdsInOneOfRules = oneOfRules.map(({ optionIds }) => without(id, optionIds)).flat();

    newSelectedOptions = without(otherOptionIdsInOneOfRules, newSelectedOptions);
    newActualOptionIds = without(otherOptionIdsInOneOfRules, newActualOptionIds);
  }

  const includedInRules = getRulesOfTypeForOption(id, 'IN', state);

  if (includedInRules.length > 0) {
    includedInRules.forEach(({ optionIds, primaryOptionId }) => {
      if (primaryOptionId === id) {
        newSelectedOptions = newSelectedOptions.concat(optionIds);
      }
    });
  }

  return {
    ...state,
    selected: {
      ...state.selected,
      optionIds: newSelectedOptions,
      actuallySelectedOptionIds: newActualOptionIds,
      ruleIds: getRulesForOptions(newSelectedOptions, state),
    },
  };
};

//    removeOption :: (String, State) -> State
const removeOption = (id, state) => {
  const newActualOptions = without(id, state.selected.actuallySelectedOptionIds);
  let newSelectedOptions = without(id, state.selected.optionIds);
  const ruleIds = getRulesForOptions(newSelectedOptions, state);

  const includedInRules = getRulesOfTypeForOption(id, 'IN', state);

  if (includedInRules.length > 0) {
    includedInRules.forEach(({ optionIds, primaryOptionId }) => {
      if (primaryOptionId === id) {
        newSelectedOptions = without(optionIds, newSelectedOptions);
      }
    });
  }

  return {
    ...state,
    selected: {
      ...state.selected,
      optionIds: newSelectedOptions,
      actuallySelectedOptionIds: newActualOptions,
      ruleIds,
    },
  };
};

// eslint-disable-next-line default-param-last
const DealReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_DEAL_STATE':
      return { ...initialState };
    case 'INITIALISE':
      return {
        ...initialState,
        ...action.payload,
        initialised: true,
      };
    case 'SET_DEAL_CURRENT_STEP':
      return {
        ...state,
        currentStep: action.payload,
      };
    case 'CLEAR_SELECTED_OPTIONS':
      return {
        ...state,
        currentStep: 1,
        selected: {
          optionIds: [],
          actuallySelectedOptionIds: [],
          ruleIds: [],
        },
      };
    case 'SET_INITIAL_VALUES':
      return {
        ...state,
        annualMileage: parseInt(action.payload.mileage, 10),
        contractLength: parseInt(action.payload.term, 10),
      };
    case 'STORE_INITIAL_PAYMENT':
      return {
        ...state,
        initialPaymentPrice: action.payload,
      };
    case 'STORE_MONTHLY_PRICE':
      return {
        ...state,
        monthlyPrice: action.payload,
      };
    case 'STORE_CONTRACT_LENGTH':
      return {
        ...state,
        contractLength: action.payload,
      };
    case 'STORE_ANNUAL_MILEAGE':
      return {
        ...state,
        annualMileage: action.payload,
      };
    case 'STORE_INITIAL_MONTHS':
      return {
        ...state,
        initialMonths: action.payload,
      };
    case 'STORE_MAINTENANCE_SELECTED':
      return {
        ...state,
        maintenanceSelected: action.payload,
      };
    case 'OFFER.SET_ID':
      return {
        ...state,
        offerId: action.id,
      };
    case 'OFFER.DERIVATIVE_ID':
      return {
        ...state,
        derivativeId: action.id,
      };
    case 'DEAL.SET_ID':
      return {
        ...state,
        dealId: action.id,
      };
    case 'STORE_UPDATED_PRICE':
      return {
        ...state,
        dealId: action.payload.dealId,
        derivativeId: action.payload.derivativeId,
        initialPaymentPrice: action.payload.initialPaymentPrice,
        isPersonal: action.payload.isPersonal,
        lx_monthly_increase: action.payload.lx_monthly_increase,
        maintainedInitialPaymentPrice: action.payload.maintainedInitialPaymentPrice,
        maintainedMonthlyPrice: action.payload.maintainedMonthlyPrice,
        monthlyPrice: action.payload.monthlyPrice,
        offerId: action.payload.offerId,
        maintainedOfferId: action.payload.maintainedOfferId,
        URLParamsApplied: !!(state.options && state.options.length),
        pluginOrElectric: action.payload.pluginOrElectric,
      };
    case 'STORE_FINANCE_LEASE':
      return {
        ...state,
        isFinanceLease: action.payload,
      };
    case 'OPTIONS.INIT':
      return {
        ...state,
        optionsIsPersonal: action.isPersonal,
        options: action.options,
        rules: action.rules,
        categories: action.categories,
        isStock: action.isStock,
        isFrozen: action.isFrozen,
        isAdmin: action.isAdmin,
        URLParamsApplied: false,
      };
    case 'OPTIONS.ADD':
      return addOption(action.id, state);

    case 'OPTIONS.REMOVE':
      return removeOption(action.id, state);
    case 'DEAL_URL_PARAMS_INIT':
      return {
        ...state,
        URLParamsInit: true,
        selected: {
          ...state.selected,
          actuallySelectedOptionIds: action.payload,
        },
      };
    case 'SET_TURBOLINKS_LOAD':
      return {
        ...state,
        isTurbolinksLoad: false,
      };
    case 'SET_DEAL_SALES_VIEW':
      return {
        ...state,
        salesView: action.payload,
      };
    default:
      return state;
  }
};

export default DealReducer;
