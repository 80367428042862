const initialState = {
  currentStep: 1,
  dealID: '',

  // Personal Details
  title: '',
  firstName: '',
  surname: '',
  dateOfBirth: '',
  telephone: '',
  email: '',
  maritalStatus: '',
  numberOfDependents: '',

  // Accommodation History
  propertyStatus: '',

  // Primary Address
  primaryPostcode: '',
  primaryAddressLine1: '',
  primaryAddressLine2: '',
  primaryAddressLine3: '',
  primaryTown: '',
  primaryCounty: '',
  yearsAtPrimaryAddress: '',
  monthsAtPrimaryAddress: '',

  // Secondary Address
  secondaryPostcode: '',
  secondaryAddressLine1: '',
  secondaryAddressLine2: '',
  secondaryAddressLine3: '',
  secondaryTown: '',
  secondaryCounty: '',
  yearsAtSecondaryAddress: '',
  monthsAtSecondaryAddress: '',
};

// eslint-disable-next-line default-param-last
const OCAPersonalInfo = (state = initialState, action) => {
  let newState;

  if (action.type === 'RESET_OCA_PERSONAL_INFO_STATE') {
    return { ...initialState };
  }

  // Step Tracker
  if (action.type === 'SET_DEAL_ID') {
    newState = { ...state };
    newState.dealID = action.payload;
    return newState;
  }

  if (action.type === 'SET_OCA_CURRENT_STEP') {
    newState = { ...state };
    newState.currentStep = action.payload;
    return newState;
  }

  // Personal Details
  if (action.type === 'SET_TITLE') {
    newState = { ...state };
    newState.title = action.payload;
    return newState;
  }

  if (action.type === 'SET_FIRST_NAME') {
    newState = { ...state };
    newState.firstName = action.payload;
    return newState;
  }

  if (action.type === 'SET_MIDDLE_NAME') {
    newState = { ...state };
    newState.middleName = action.payload;
    return newState;
  }

  if (action.type === 'SET_SURNAME') {
    newState = { ...state };
    newState.surname = action.payload;
    return newState;
  }

  if (action.type === 'SET_DATE_OF_BIRTH') {
    newState = { ...state };
    newState.dateOfBirth = action.payload;
    return newState;
  }

  if (action.type === 'SET_TELEPHONE') {
    newState = { ...state };
    newState.telephone = action.payload;
    return newState;
  }

  if (action.type === 'SET_EMAIL') {
    newState = { ...state };
    newState.email = action.payload;
    return newState;
  }

  if (action.type === 'SET_MARITAL_STATUS') {
    newState = { ...state };
    newState.maritalStatus = action.payload;
    return newState;
  }

  if (action.type === 'SET_NUMBER_OF_DEPENDENTS') {
    newState = { ...state };
    newState.numberOfDependents = action.payload;
    return newState;
  }

  // Accommodation History
  if (action.type === 'SET_PROPERTY_STATUS') {
    newState = { ...state };
    newState.propertyStatus = action.payload;
    return newState;
  }

  // Primary Address
  if (action.type === 'SET_PRIMARY_POSTCODE') {
    newState = { ...state };
    newState.primaryPostcode = action.payload;
    return newState;
  }

  if (action.type === 'SET_PRIMARY_ADDRESS_LINE_1') {
    newState = { ...state };
    newState.primaryAddressLine1 = action.payload;
    return newState;
  }

  if (action.type === 'SET_PRIMARY_ADDRESS_LINE_2') {
    newState = { ...state };
    newState.primaryAddressLine2 = action.payload;
    return newState;
  }

  if (action.type === 'SET_PRIMARY_ADDRESS_LINE_3') {
    newState = { ...state };
    newState.primaryAddressLine3 = action.payload;
    return newState;
  }

  if (action.type === 'SET_PRIMARY_TOWN') {
    newState = { ...state };
    newState.primaryTown = action.payload;
    return newState;
  }

  if (action.type === 'SET_PRIMARY_COUNTY') {
    newState = { ...state };
    newState.primaryCounty = action.payload;
    return newState;
  }

  if (action.type === 'SET_YEARS_AT_PRIMARY_ADDRESS') {
    newState = { ...state };
    newState.yearsAtPrimaryAddress = action.payload;
    return newState;
  }

  if (action.type === 'SET_MONTHS_AT_PRIMARY_ADDRESS') {
    newState = { ...state };
    newState.monthsAtPrimaryAddress = action.payload;
    return newState;
  }

  // Secondary Address
  if (action.type === 'SET_SECONDARY_POSTCODE') {
    newState = { ...state };
    newState.secondaryPostcode = action.payload;
    return newState;
  }

  if (action.type === 'SET_SECONDARY_ADDRESS_LINE_1') {
    newState = { ...state };
    newState.secondaryAddressLine1 = action.payload;
    return newState;
  }

  if (action.type === 'SET_SECONDARY_ADDRESS_LINE_2') {
    newState = { ...state };
    newState.secondaryAddressLine2 = action.payload;
    return newState;
  }

  if (action.type === 'SET_SECONDARY_ADDRESS_LINE_3') {
    newState = { ...state };
    newState.secondaryAddressLine3 = action.payload;
    return newState;
  }

  if (action.type === 'SET_SECONDARY_TOWN') {
    newState = { ...state };
    newState.secondaryTown = action.payload;
    return newState;
  }

  if (action.type === 'SET_SECONDARY_COUNTY') {
    newState = { ...state };
    newState.secondaryCounty = action.payload;
    return newState;
  }

  if (action.type === 'SET_YEARS_AT_SECONDARY_ADDRESS') {
    newState = { ...state };
    newState.yearsAtSecondaryAddress = action.payload;
    return newState;
  }

  if (action.type === 'SET_MONTHS_AT_SECONDARY_ADDRESS') {
    newState = { ...state };
    newState.monthsAtSecondaryAddress = action.payload;
    return newState;
  }

  return state;
};

export default OCAPersonalInfo;
